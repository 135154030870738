import React from "react";
import { Switch, Route } from 'react-router-dom';
import * as routeData from './../routes/routesConfig'

import Loadable from 'react-loadable';
import Loading  from '../components/Common/loaderComponent';
import PrivateRoute from "./privateRoute";

const LoadableAuthentication = Loadable({
    loader: () => import ('./../pages/Authentication/authentication'),
    loading: Loading
})

const LoadableLogin = Loadable({
    loader: () => import("../pages/Login/loginPage"),
    loading: Loading
})
const LoadableRegister = Loadable({
    loader: () => import("../pages/Register/registerPage"),
    loading: Loading
})
const LoadableRecovery = Loadable({
    loader: () => import("../pages/Recovery/recoveryPage"),
    loading: Loading
})

const LoadableHome = Loadable({
    loader: () => import('../pages/Home/homePage'),
    loading: Loading
})

const LoadableSuccess = Loadable({
    loader: () => import("./../pages/Success/successPage"),
    loading: Loading
})

const LoadableProgram = Loadable({
    loader: () => import("./../pages/Program/programPage"),
    loading: Loading
})

const LoadableHabit = Loadable({
    loader: () => import("./../pages/Habit/habitPage"),
    loading: Loading
})

const LoadableProfile = Loadable({
    loader: () => import("./../pages/Profile/profilePage"),
    loading: Loading
})

const LoadableActivities = Loadable({
    loader: () => import("./../pages/Activities/activitiesPage"),
    loading: Loading
})

const LoadableAdvices = Loadable({
    loader: () => import("./../pages/Advices/advices"),
    loading: Loading
})

const LoadablePersonalisation = Loadable({
    loader: () => import("./../pages/Personalisation/personalisation"),
    loading: Loading
})

const LoadablePersonalisationCja = Loadable({
    loader: () => import("./../pages/Personalisation/personalisationCjaPage"),
    loading: Loading
})

const LoadableResignation = Loadable({
    loader: () => import("./../pages/Resignation/resignationPage"),
    loading: Loading
})

export default function Routes(){
    return(
        <Switch>
            <Route exact path="/" component={LoadableLogin} />
            <PrivateRoute path={routeData.PAGE_HOME.path} component={LoadableHome} />
            <PrivateRoute path={routeData.PAGE_SUCCESS.path} component={LoadableSuccess} />
            <PrivateRoute path={routeData.PAGE_ACTIVITIES.path} component={LoadableActivities} />
            <PrivateRoute path={routeData.PAGE_PROGRAM.path} component={LoadableProgram} />
            <PrivateRoute path={routeData.PAGE_HABIT.path} component={LoadableHabit} />
            <PrivateRoute path={routeData.PAGE_PROFILE.path} component={LoadableProfile} />
            <PrivateRoute path={routeData.PAGE_ADVICES.path} component={LoadableAdvices} />
            <PrivateRoute path={routeData.PAGE_PERSONALISATION.path} component={LoadablePersonalisation} />
            <PrivateRoute path={routeData.PAGE_PERSONALISATION_CJA.path} component={LoadablePersonalisationCja} />
            <PrivateRoute path={routeData.PAGE_RESIGNATION.path} component={LoadableResignation} />
            <Route path={routeData.PAGES_RECOVERY.path} component={LoadableRecovery} />
            <Route path={routeData.PAGES_REGISTER.path} component={LoadableRegister} />
            <Route path={routeData.PAGES_AUTHENTICATION.path} component={LoadableAuthentication} />
            <Route component={LoadableLogin} />
        </Switch>
    )
}
