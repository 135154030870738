// export const locale = process.env.REACT_APP_API_LANG ? process.env.REACT_APP_API_LANG : 'fr'


export const setLocale = () => {
    const infos = JSON.parse(sessionStorage.getItem('infos'));

    if (infos) {
        const {brand} = infos;
        const {externalId} = brand ? brand[0] : "";
        if ( brand && externalId && externalId === 6){
            return 'it';
        }
    }
    return 'fr';
}

export const locale = setLocale();
