import {Col, Row} from "react-bootstrap";
import React from "react";
import {trans} from "../../../../../../translations/translations";

const FrustrationAdvice4 = () => {
    return (
        <Row className={"response-row"}>
            <Col xs={12} className={"title mb-2"}>
                <p className={"m-0"}>
                    <span className={"me-1"}>{trans('results.answer3.frustration.advice4.title.text1', 'sos')}</span>
                    <span className={"me-1 text-pink text-bold"}>{trans('results.answer3.frustration.advice4.title.text2', 'sos')}</span>
                </p>
            </Col>
            <Col xs={12} className={"text-result mb-3"}>
                <p className={"m-0"}>{trans('results.answer3.frustration.advice4.sub.text1', 'sos')}</p>
            </Col>
        </Row>
    )
}

export default FrustrationAdvice4;
