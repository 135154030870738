import {Col, Row} from "react-bootstrap";
import {trans} from "../../../../../../translations/translations";

const DesireAdvice1 =() => {
    return (
        <Row className={"response-row"}>
            <Col xs={12} className={"title mb-2"}>
                <p className={"m-0"}>
                    <span className={"me-1 text-pink text-bold"}>{trans('results.answer1.desire.advice1.title.text1', 'sos')}</span>
                    <span className={"me-1 "}>{trans('results.answer1.desire.advice1.title.text2', 'sos')}</span>
                </p>
            </Col>
            <Col xs={12} className={"text-results mb-3"}>
                <p className={"m-0"}>{trans('results.answer1.desire.advice1.sub.text1', 'sos')}</p>
            </Col>
            <Col xs={12} className={"text-results mb-3"}>
                <p className={"m-0"}>{trans('results.answer1.desire.advice1.sub.text2', 'sos')}</p>
                <p className={"m-0"}>{trans('results.answer1.desire.advice1.sub.text3', 'sos')}</p>
            </Col>
            <Col xs={12} className={"text-results mb-3"}>
                <p className={"m-0"}>{trans('results.answer1.desire.advice1.sub.text4', 'sos')}</p>
            </Col>
            <Col xs={12} className={"text-results mb-3"}>
                <p className={"m-0"}>{trans('results.answer1.desire.advice1.sub.text5', 'sos')}</p>
                <p className={"m-0"}>{trans('results.answer1.desire.advice1.sub.text6', 'sos')}</p>
            </Col>
        </Row>
    )
}

export default DesireAdvice1;
