import React, {useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";
import BasePinkButton from "../../Button/basePinkBtn";
import {useSelector} from "react-redux";
import {question1Helper} from "./question1Helper";
import {trans} from "../../../translations/translations";

const Question1Component = (prop) => {

    const {handleResponse} = prop
    const {step, response} = useSelector(({sosBreakingReducer}) => sosBreakingReducer);
    const [statusBtn1, setStatusBtn1] = useState("no-selected")
    const [statusBtn2, setStatusBtn2] = useState("no-selected")
    const [responseSelected, setResponseSelected] = useState(null)

    const handleClickResponse = (event) => {
        setResponseSelected(parseInt(event.currentTarget.dataset.btn))
    }

    useEffect(() => {
        if(responseSelected){
            handleResponse(responseSelected)
        }
        // eslint-disable-next-line
    },[responseSelected])

    useEffect(() => {
        if(responseSelected === 1){
            setStatusBtn1('selected')
            setStatusBtn2('no-selected')
        }else if(responseSelected === 2){
            setStatusBtn2('selected')
            setStatusBtn1('no-selected')
        }
    },[responseSelected])

    useEffect(() => {
       const recordedResponse = response.filter( response => response.R === step)[0]
        if( recordedResponse && recordedResponse.value === 1){
            setStatusBtn1('selected')
        }else if(recordedResponse && recordedResponse.value === 2){
            setStatusBtn2('selected')
        }
        // eslint-disable-next-line
    }, [response]);


    return(
        <Row className={"q-row position-relative justify-content-center px-sm-3 py-4"}>
            <div className={"title"}>
                {trans('question1.title', 'sos')}
            </div>
            <Col xs={11} sm={12} className={"text-question text-center my-3"}>
                <span className={"me-1"}>{trans('question1.text1', 'sos')}</span>
                <span className={"me-1 text-pink"}>{trans('question1.text2', 'sos')}</span>
                <span>{trans('question1.text3', 'sos')}</span>
            </Col>
            <Col xs={6} sm={6} className={"btn-question text-end"}>
                <BasePinkButton label={question1Helper[0].text} prefix={'sos'} onClick={handleClickResponse} status={statusBtn1} data={question1Helper[0].id}/>
            </Col>
            <Col xs={6} sm={6} className={"btn-question text-start"}>
                <BasePinkButton label={question1Helper[1].text} prefix={'sos'} onClick={handleClickResponse} status={statusBtn2} data={question1Helper[1].id}/>
            </Col>
            <Col xs={12} sm={11} className={"desc-question mt-4"}>
                <p className={"m-0 text-center px-1"}>
                    {trans('question1.description', 'sos')}
                </p>
            </Col>
        </Row>
    )
}

export default Question1Component;
