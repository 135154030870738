import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {trans} from "../../translations/translations";
import {sosBreakingActions} from "../../_actions/sosBreakingActions";
import {Col, Row} from "react-bootstrap";
import * as ImIcons from "react-icons/im";
import {ReactComponent as Logo} from "../../asset/image/CommeJaime_logo.svg";
import '../../asset/css/Common/header.scss';
import {sendNavigationStats} from "../../_services/statsServices";
import {statsPage} from "../../_constants/statsConstants";


const Header = () => {

    const dispatch = useDispatch();
    const {isLoggedIn} = useSelector(state => {
        const {authReducer} = state;
        return authReducer;
    });

    const sosBreaking = (e) => {
        e.preventDefault();
        dispatch(sosBreakingActions.sosBreakingOpenForm())
        sendNavigationStats(statsPage.SOS);
    }

    return (
        <Row className="header-block">
            <Col xs={6} sm={"auto"} className={"header-logo"}>
                <Logo/>
            </Col>
            <Col xs={"auto"} className={"d-none d-md-block p-0"}>
                <span className="header-title d-none d-sm-inline-block">{trans('header.title')}</span>
            </Col>
            {
                isLoggedIn && (
                    <>
                        <Col xs={6} xl={2} className="icon-block ms-md-auto">
                            <Row className={"g-0"}>
                                <Col xs={12}>
                                    <a href="/" className={"text-decoration-none"} title={trans("header.sos")}
                                       onClick={sosBreaking}>
                                        <Row className={"justify-content-center"}>
                                            <Col xs={"auto"} className="btn-icon p-0 mb-2">
                                                <ImIcons.ImLifebuoy/>
                                            </Col>
                                            <Col xs={12} className={"text-center text-icon"}>
                                                <span>{trans('header.sos')}</span>
                                            </Col>
                                        </Row>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </>
                )
            }
        </Row>
    )
}

export default Header;
