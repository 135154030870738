export const trans_IT = {
    services:{
        email:{
            customer: "clienti@mipiacecosi.it"
        },
        phone:{
            customer: "02/83595151",
        },
    },
}
