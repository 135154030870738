import AxiosInstance from "../_services/axiosServices";
import {statsEvent} from "../_constants/statsConstants";
import{EndPointCustomerHub} from "../_constants"

export const sendRdvStats = () => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.RDV,
        customerId: infos.externalId ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendConnectionStats = (externalId) => {
    const data = {
        refEvent: statsEvent.CONNECTION,
        customerId: externalId ?? 0
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendNavigationStats = (page) => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.NAVIGATION,
        customerId: infos.externalId ?? "",
        refPage: page ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendPersonalisationCjaStats = (commandId) => {
    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.PERSONALISATION_CJA_EVENTS,
        customerId: infos.externalId ?? "",
        commandId: commandId
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendAdviceStats = (advice) => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.ADVICE,
        customerId: infos.externalId ?? "",
        advice: advice ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendQuestionStats = (question) => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.QUESTION,
        customerId: infos.externalId ?? "",
        numQuestion: question ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendBreakStats = () => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.BREAK,
        customerId: infos.externalId ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendNoBreakStats = () => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.NO_BREAK,
        customerId: infos.externalId ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendCallBackStats = () => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.CALL_BACK_COACH,
        customerId: infos.externalId ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}

export const sendSosStats = (response) => {

    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        refEvent: statsEvent.SOS,
        customerId: infos.externalId ?? "",
    }
    response.forEach((el) => {
        if( el.R === 1 ){
            data.responseQ1 = el.value
        }else if (el.R === 2){
            data.responseQ2 = el.value
        }else if( el.R === 3 ){
            data.responseQ3 = []
            el.value.forEach((r) => {
                data.responseQ3.push(r)
            })
        }else if( el.R === 4 ){
            data.responseQ4 = []
            el.value.forEach((r) => {
                data.responseQ4.push(r)
            })
        }
    })
    AxiosInstance.post(EndPointCustomerHub.stats, data)
        .then()
}
