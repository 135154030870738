import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {emotions} from "./question3Helper";
import Question3IconComponent from "./question3IconComponent";
import {trans} from "../../../translations/translations";

const Question3Component = (prop) => {

    const {handleResponse} = prop
    const {step, response} = useSelector(({sosBreakingReducer}) => sosBreakingReducer);
    const [responseSelected, setResponseSelected] = useState([])
    const [emotionsList, setEmotionsList] = useState(emotions)

    const emotionRef = useRef([]);

    const handleClickResponse = idx => () => {
        const itemSelected = parseInt(emotionRef.current[idx].dataset.id);
        let newResponse;
        if(responseSelected.some( (el) => el === itemSelected ) ){
            newResponse = responseSelected.filter( el => { return el !== itemSelected})
        }else{
            newResponse = responseSelected.map((el) => {return el})
            newResponse.push(itemSelected)
        }
        setResponseSelected(newResponse)
        handleResponse( newResponse.length > 0 ? newResponse : null);
    }

    const updateStatus = (selection) => {
        return emotionsList.map( (el) => {
            if(selection.some((id) => id === el.id)){
                el.status = 'selected'
            }else{
                el.status = 'no-selected'
            }
            return el
        })
    }

    useEffect(() => {
        setEmotionsList(updateStatus(responseSelected))
        // eslint-disable-next-line
    },[responseSelected])

    useEffect(() => {
        const recordedResponse = response.filter( response => response.R === step)[0]
        if( recordedResponse && recordedResponse.value){
            setResponseSelected(recordedResponse.value)
            setEmotionsList(updateStatus(recordedResponse.value))
        }
        // eslint-disable-next-line
    }, [response]);

    return(
        <Row className={"q-row position-relative justify-content-center px-sm-3 py-4"}>
            <div className={"title"}>
                {trans('question3.title', 'sos')}
            </div>
            <Col xs={10} sm={12} className={"text-question text-center my-3 px-3"}>
                <span className={"me-1"}>{trans('question3.text1', 'sos')}</span>
                <span className={"me-1 text-pink"}>{trans('question3.text2', 'sos')}</span>
            </Col>
            <Col xs={12} sm={10} className={"mb-3"}>
                <Row className={"emotion-row justify-content-center"}>
                    {
                        emotionsList.map( (el,idx) => {
                            return <Col key={idx} ref={el => emotionRef.current[idx] = el} xs={6} sm={'auto'} className={'emotion-block d-flex justify-content-center'} data-id={el.id} onClick={handleClickResponse(idx)}>
                                <Question3IconComponent Icon={el.icon} text={el.text} status={el.status}/>
                            </Col>
                        })
                    }
                </Row>
            </Col>
        </Row>
    )
}

export default Question3Component;
