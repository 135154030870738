import {Col, Row} from "react-bootstrap";
import React from "react";
import {trans} from "../../../../../../translations/translations";

const NoLossWeightAdvice4 = () => {
    return (
        <Row className={"response-row"}>
            <Col xs={12} className={"title mb-2"}>
                <p className={"m-0"}>
                    <span className={"me-1 text-pink text-bold"}>{trans('results.answer4.loss.advice4.title.text1', 'sos')}</span>
                    <span className={"me-1"}>{trans('results.answer4.loss.advice4.title.text2', 'sos')}</span>
                </p>
            </Col>
            <Col xs={12} className={"text-result"}>
                <p className={"m-0"}>
                    {trans('results.answer4.loss.advice4.sub.text1', 'sos')}
                </p>
                <p className={"m-0"}>{trans('results.answer4.loss.advice4.sub.text2', 'sos')}</p>
                <ul>
                    <li>{trans('results.answer4.loss.advice4.sub.text3', 'sos')}</li>
                    <li>{trans('results.answer4.loss.advice4.sub.text4', 'sos')}</li>
                    <li>{trans('results.answer4.loss.advice4.sub.text5', 'sos')}</li>
                    <li>{trans('results.answer4.loss.advice4.sub.text6', 'sos')}</li>
                    <li>{trans('results.answer4.loss.advice4.sub.text7', 'sos')}</li>
                </ul>
            </Col>
            <Col xs={12} className={"text-result mb-3"}>
                <p className={"m-0"}>{trans('results.answer4.loss.advice4.sub.text8', 'sos')}</p>
            </Col>
        </Row>
    )
}

export default NoLossWeightAdvice4;
