import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {trans} from "../../../translations/translations";

const Question2Component = (prop) => {

    const {handleResponse} = prop
    const {step, response} = useSelector(({sosBreakingReducer}) => sosBreakingReducer);
    const [statusBtn1, setStatusBtn1] = useState("no-selected")
    const [statusBtn2, setStatusBtn2] = useState("no-selected")
    const [statusBtn3, setStatusBtn3] = useState("no-selected")
    const [responseSelected, setResponseSelected] = useState(null)

    const handleClickResponse = (event) => {
        setResponseSelected(parseInt(event.currentTarget.dataset.btn))
    }

    useEffect(() => {
        if(responseSelected){
            handleResponse(responseSelected)
        }
        // eslint-disable-next-line
    },[responseSelected])

    useEffect(() => {
        if(responseSelected === 1){
            setStatusBtn1('selected')
            setStatusBtn2('no-selected')
            setStatusBtn3('no-selected')
        }else if(responseSelected === 2){
            setStatusBtn2('selected')
            setStatusBtn1('no-selected')
            setStatusBtn3('no-selected')
        }else if(responseSelected === 3){
            setStatusBtn3('selected')
            setStatusBtn1('no-selected')
            setStatusBtn2('no-selected')
        }
    },[responseSelected])

    useEffect(() => {
        const recordedResponse = response.filter( response => response.R === step)[0]
        if( recordedResponse && recordedResponse.value === 1){
            setStatusBtn1('selected')
        }else if(recordedResponse && recordedResponse.value === 2){
            setStatusBtn2('selected')
        }else if(recordedResponse && recordedResponse.value === 3){
            setStatusBtn3('selected')
        }
        // eslint-disable-next-line
    }, [response]);

    return(
        <Row className={"q-row position-relative justify-content-center px-3 py-4"}>
            <div className={"title"}>
                {trans('question2.title', 'sos')}
            </div>
            <Col xs={12} className={"text-question text-center my-3"}>
                <p className={"m-0"}>{trans('question2.text1', 'sos')}</p>
                <p className={"m-0 text-pink"}>
                    <span className={"me-1"}>{trans('question2.text2', 'sos')}</span>
                    <span className={"me-1"}>{response.filter( response => response.R === 1)[0].value === 1
                        ? trans('question2.anger', 'sos')
                        : trans('question2.desire', 'sos')}</span>
                    <span>:</span>
                </p>
            </Col>
            <Col xs={12} className={"mb-3"}>
                <Row className={"btn-row justify-content-center"}>
                    <Col xs={4} sm={"auto"} className={"block-btn px-0"} data-btn={1} onClick={handleClickResponse}>
                        <Row className={"g-0"}>
                            <Col xs={12} className={"block-circle d-flex justify-content-center mb-2"}>
                                <div className={"btn-circle px-0 d-flex justify-content-center align-items-center " + statusBtn1}>1</div>
                            </Col>
                            <Col xs={12} className={"text-circle text-center"}>
                                <span>{trans('question2.low', 'sos')}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} sm={"auto"} className={"block-btn px-0"} data-btn={2} onClick={handleClickResponse}>
                        <Row className={"g-0"}>
                            <Col xs={12} className={"block-circle d-flex justify-content-center"}>
                                <div className={"btn-circle px-0 d-flex justify-content-center align-items-center " + statusBtn2}>2</div>
                            </Col>
                            <Col xs={12} className={"text-circle"} />
                        </Row>
                    </Col>
                    <Col xs={4} sm={"auto"} className={"block-btn"} data-btn={3} onClick={handleClickResponse}>
                        <Row className={"g-0"}>
                            <Col xs={12} className={"block-circle d-flex justify-content-center mb-2"}>
                                <div className={"btn-circle px-0 d-flex justify-content-center align-items-center " + statusBtn3}>3</div>
                            </Col>
                            <Col xs={12} className={"text-circle text-center"}>
                                <span>{trans('question2.high', 'sos')}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Question2Component;
