import {defaultTransFiles, transFiles} from "./index";
import {setLocale} from "../_helpers/_locale";

export const trans: React.FC = (target, domain = "messages") => {
    const locale = setLocale();
    const langFile = transFiles.find(el => el.lang === locale && el.domain === domain)
    if(langFile === undefined){
        return defaultLang(target,domain)
    }
    const tr = translation(langFile.file, target)
    return tr ? tr : defaultLang(target, domain)
}

const defaultLang = (string, domain) => {
    const langFile = defaultTransFiles.find(el => el.domain === domain)
    const dLang = translation(langFile.file, string)
    if(!dLang){
        return string
    }
    return dLang
}

const translation = (obj, string) => {
    const s = string.split(".")
    const target = Object.entries(obj).find(el => el[0] === s[0])
    if( target === undefined){
        return false
    }else if( typeof target[1] !== 'object' && s.length === 1){
        return target[1]
    }else if( typeof target[1] === 'object'){
        return translation(target[1],s.slice(1).join('.'))
    }
}


