import React, {useEffect, useState} from "react";
import {Modal, Row, Col} from "react-bootstrap";
import {sosBreakingStep} from "../../../_constants/sosBreakingConstants";
import {sosBreakingActions} from "../../../_actions/sosBreakingActions";
import {question1Helper} from "../modalContentComponent/question1Helper";
import {useDispatch, useSelector} from "react-redux";
import * as ImIcons from "react-icons/im";
import BasePinkButton from "../../Button/basePinkBtn";
import Question1Component from "../modalContentComponent/question1Component";
import ResultComponent from "../modalContentComponent/resultComponent";
import Question2Component from "../modalContentComponent/question2Component";
import Question3Component from "../modalContentComponent/question3Component";
import Question4Component from "../modalContentComponent/question4Component";
import {
    sendQuestionStats,
    sendBreakStats,
    sendNoBreakStats
} from "../../../_services/statsServices";
import {trans} from "../../../translations/translations";


const ModalFormComponent = (prop) => {

    const {show} = prop;
    const {step, response} = useSelector(({sosBreakingReducer}) => sosBreakingReducer);
    const [btnDisabled, setBtnDisabled] = useState('disabled')
    const [noBreakButtonText, setNoBreakButtonText] = useState('')
    const [stats, setStats] = useState(false);

    const dispatch = useDispatch()

    const handleCloseForm = () => dispatch(sosBreakingActions.sosBreakingCloseForm());

    const next = () => {
        dispatch(sosBreakingActions.sosBreakingUpStep(step + 1))
        if(!stats || step > stats){
            sendQuestionStats(step)
            setStats(step)
        }
    }

    const previous = () => {
        dispatch(sosBreakingActions.sosBreakingUpStep(step - 1))
    }

    const noBreak = () => {
        sendNoBreakStats();
        dispatch(sosBreakingActions.sosBreakingUpStep(sosBreakingStep.NO_BREAK))
        dispatch(sosBreakingActions.sosBreakingCloseForm());
        dispatch(sosBreakingActions.sosBreakingOpenDialog());
    }

    const bigBreak = () => {
        sendBreakStats();
        dispatch(sosBreakingActions.sosBreakingUpStep(sosBreakingStep.BIG_BREAK))
        dispatch(sosBreakingActions.sosBreakingCloseForm());
        dispatch(sosBreakingActions.sosBreakingOpenDialog());
    }

    const handleResponse = (responseSelected) => {

        if( response.some( (el) => el.R === step ) ){
             response.map( el => {
                if( el.R === step ){
                    el.value = responseSelected
                }
                return el
            })
        }else{
            response.push({R: step, value: responseSelected})
        }
        dispatch(sosBreakingActions.sosBreakingAddResponse(response))
        handleBtn()
    }

    const handleBtn = () => {
        const recordedResponse = response.filter( response => response.R === step)[0]
        if( recordedResponse && recordedResponse.value ){
            setBtnDisabled('')
        }else{
            setBtnDisabled('disabled')
        }
    }

    const dynamicNoBreakButton = () => {
        const response1 = response.filter( response => response.R === 1)[0]
        if( response1 && response1.value === question1Helper[0].id ){                                               // faim
            setNoBreakButtonText(trans('btn.angryOk', 'sos'))
        }else if( response1 && response1.value === question1Helper[1].id ){                                         // envie
            setNoBreakButtonText(trans('btn.desireOk', 'sos'))
        }
    }

    useEffect(() => {
        handleBtn()
        dynamicNoBreakButton();
        // eslint-disable-next-line
    }, [response,step]);

    return (
        <Modal className={"sos-modal-form"} show={show} onHide={handleCloseForm}>
            <Modal.Header className={"header-sos"} closeButton />
            <Modal.Body className={"body-sos pt-0"}>
                <Row className={"header justify-content-center mb-3 mb-md-5"}>
                    <Col xs={12} className={"text-center mb-4"}>
                        <ImIcons.ImLifebuoy/>
                    </Col>
                    <Col xs={12} className={"text-center title mb-2"}>
                        <p className={"m-0"}>{trans('title', 'sos')}</p>
                    </Col>
                    {
                        step === parseInt(sosBreakingStep.RESULT) &&
                        <>
                            <Col xs={12} sm={10} className={"text-header-result mb-2"}>
                                <p className={"text-center m-0"}>
                                    <span className={"me-1"}>
                                       {trans('results.text1', 'sos')}
                                    </span>
                                </p>
                            </Col>
                        </>
                    }
                    {
                        step !== parseInt(sosBreakingStep.RESULT) &&
                        <>
                            <Col xs={12} className={"text-header mb-2"}>
                                <p className={"text-center m-0"}>{trans('questions.text1', 'sos')}</p>
                                <p className={"text-center m-0"}>{trans('questions.text2', 'sos')}</p>
                            </Col>
                            <Col xs={12} className={"text-header"}>
                                <p className={"text-center m-0"}>{trans('questions.text3', 'sos')}</p>
                                <p className={"text-center m-0"}>{trans('questions.text4', 'sos')}</p>
                            </Col>
                        </>
                    }

                </Row>
                <Row className={"content mb-3 justify-content-center"}>
                    <Col xs={12} sm={10} className={"mb-2"}>
                        {
                            step === parseInt(sosBreakingStep.Q1) &&
                                <Question1Component handleResponse={handleResponse}/>
                        }
                        {
                            step === parseInt(sosBreakingStep.Q2) &&
                                <Question2Component handleResponse={handleResponse}/>
                        }
                        {
                            step === parseInt(sosBreakingStep.Q3) &&
                                <Question3Component handleResponse={handleResponse}/>
                        }
                        {
                            step === parseInt(sosBreakingStep.Q4) &&
                                <Question4Component handleResponse={handleResponse}/>
                        }
                        {
                            step === parseInt(sosBreakingStep.RESULT) &&
                                <ResultComponent />
                        }
                    </Col>
                </Row>
                <Row className={"footer mb-2 justify-content-center py-2"}>
                    <Col xs={10} className={"px-0"}>
                        <Row >
                            <Col xs={6} sm={6} className={"text-start d-flex align-items-center px-0"}>
                                {
                                    step > parseInt(sosBreakingStep.Q1) && step !== parseInt(sosBreakingStep.RESULT) &&
                                    <p className={"m-0 text-link"} onClick={previous}>{trans('btn.prevQuestion', 'sos')}</p>
                                }
                                {
                                    step === parseInt(sosBreakingStep.RESULT) &&
                                    <p className={"m-0 text-link"} onClick={bigBreak}>{trans('btn.breakDown', 'sos')}</p>
                                }
                            </Col>
                            <Col xs={6} sm={6} className={"text-end px-0"}>
                                {
                                    step !== parseInt(sosBreakingStep.RESULT) && step !== parseInt(sosBreakingStep.Q4) &&
                                    <BasePinkButton label={trans('btn.nextQuestion', 'sos')} onClick={next} disabled={btnDisabled}/>
                                }
                                {
                                    step !== parseInt(sosBreakingStep.RESULT) && step === parseInt(sosBreakingStep.Q4) &&
                                    <BasePinkButton label={trans('btn.sendSos', 'sos')} onClick={next} disabled={btnDisabled}/>
                                }
                                {
                                    step === parseInt(sosBreakingStep.RESULT) &&
                                    <BasePinkButton label={noBreakButtonText} onClick={noBreak}/>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalFormComponent;
