export const statsEvent = {
    CONNECTION: "log",
    NAVIGATION: "nav",
    RDV: "rdv",
    ADVICE: "adv",
    QUESTION: "que",
    SOS: "sos",
    BREAK: "brk",
    NO_BREAK: "nobrk",
    CALL_BACK_COACH: "cal",
    PERSONALISATION_CJA_EVENTS: 'persocja'
}

export const statsPage = {
    SUCCESS: "suc",
    PROGRAM: "prog",
    ADVICE: "adv",
    ACTIVITY: "act",
    HABIT: "hab",
    PROFIL: "pro",
    DATA: "dat",
    COMMAND: "cmd",
    HELP: "help",
    PERSONALISATION: "self",
    PERSONALISATION_CJA: "persocja",
    LEARNING: "learn",
    SOS: "sos"
}
