import {customerConstants, passwordTypeRef} from '../_constants';
import {setBrand} from "../_helpers/_brand/checkBrand";
import {setRules} from "../_helpers/_rules/rules";

const infos  = JSON.parse(sessionStorage.getItem("infos"));

const initialState = infos
    ? {
        onProgress: false,
        onProgressCustomer: false,
        customerRequested: true,
        passwordType: infos.passwordType,
        newPasswordRequested: false,
        newPasswordChanged: false,
        resetOnProgress: false,
        resetPasswordRequested: false,
        resetPasswordChanged: false,
        checkOnProgress: false,
        checkUpdated: false,
        checkCgu: infos.checkCgu,
        checkData: infos.checkData,
        profile: infos.profile,
        infosLoaded: false,
        brand: infos.brand,
        rules: infos.rules,
    }
    : {
        onProgress: false,
        onProgressCustomer: false,
        customerRequested: false,
        passwordType: null,
        newPasswordRequested: false,
        newPasswordChanged: false,
        resetOnProgress: false,
        resetPasswordRequested: false,
        resetPasswordChanged: false,
        checkOnProgress: false,
        checkUpdated: false,
        checkCgu: false,
        checkData: false,
        profile: null,
        infosLoaded: false,
        brand:null,
        rules:null,
    }

export function customerReducer( state = initialState, action) {
    switch (action.type) {
        case customerConstants.CUSTOMER_RESET_MDP_RAZ:
            return{
                ...state,
                resetPasswordRequested: false,
                resetPasswordChanged: false,
                newPasswordRequested: false,
                newPasswordChanged: false,
            }
        case customerConstants.LEGAL_CHECK_UPDATE_REQUEST:
            return {
                ...state,
                checkOnProgress: true,
                checkUpdated: false
            }
        case customerConstants.LEGAL_CHECK_UPDATE_SUCCESS:
            return {
                ...state,
                checkOnProgress: false,
                checkUpdated: true,
                checkCgu: true,
                checkData: true,
            }
        case customerConstants.LEGAL_CHECK_UPDATE_FAILURE:
            return {
                ...state,
                checkOnProgress: false,
                checkUpdated: false
            }
        case customerConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetOnProgress: true,
                resetPasswordRequested: false,
                resetPasswordChanged: false
            }
        case customerConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetOnProgress: false,
                resetPasswordRequested: true,
                resetPasswordChanged: true
            }
        case customerConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetOnProgress: false,
                resetPasswordRequested: true,
                resetPasswordChanged: false
            }
        case customerConstants.NEW_PASSWORD_REQUEST:
            return {
                ...state,
                newPasswordRequested: false,
                newPasswordChanged: false,
                onProgress: true
            }
        case customerConstants.NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                onProgress: false,
                newPasswordRequested: true,
                newPasswordChanged: true,
                passwordType: action.passwordType
            }
        case customerConstants.NEW_PASSWORD_FAILURE:
            return {
                ...state,
                onProgress: false,
                newPasswordRequested: true,
                newPasswordChanged: false
            }
        case customerConstants.CUSTOMER_REQUEST:
            return {
                ...state,
                customerRequested: false,
                onProgressCustomer: true
            };
        case customerConstants.CUSTOMER_SUCCESS:
            const {marques, passwordType, rules} = action.customer;
            return {
                ...state,
                onProgressCustomer: false,
                customerRequested: true,
                infosLoaded: true,
                checkCgu: true,
                checkData: true,
                passwordType: action.customer.passwordType ? passwordType.type_id : passwordTypeRef.user,
                brand: setBrand(marques),
                rules: setRules(rules)
            };
        case customerConstants.CUSTOMER_FAILURE:
            return {
                ...state,
                onProgressCustomer: false,
                customerRequested: true,
                error: action.error
            };
        case customerConstants.DELETE_CUSTOMER:
            return{
                ...state,
                onProgress: false,
                onProgressCustomer: false,
                customerRequested: false,
                passwordType: null,
                newPasswordRequested: false,
                newPasswordChanged: false,
                resetOnProgress: false,
                resetPasswordRequested: false,
                resetPasswordChanged: false,
                checkOnProgress: false,
                checkUpdated: false,
                checkCgu: false,
                checkData: false,
                profile: null,
                infosLoaded: false,
                brand:null,
                rules:null,
            }
        default:
            return state
    }
}
