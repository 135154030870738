import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {sosBreakingActions} from "../../../_actions/sosBreakingActions";
import responseHandlerComponent from "../responsesComponent/responsesHandlerComponent";
import {sendSosStats} from "../../../_services/statsServices";

const ResultComponent = () => {

    const {openForm, response, stats} = useSelector(({sosBreakingReducer}) => sosBreakingReducer)
    const [responsesDisplayed, setResponsesDisplayed] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if(!stats){
            sendSosStats(response)
            dispatch(sosBreakingActions.sosBreakingStats());
        }
    },[stats,response,dispatch])

    useEffect(() => {
        setResponsesDisplayed(responseHandlerComponent(response))
        // setResponsesDisplayed(responseHandlerComponent(mockResponse))
    }, [response, openForm]);

    return(
        <Row className={"result-row justify-content-center"}>
                {
                    responsesDisplayed &&
                        responsesDisplayed.map( (el, idx) => {
                            return <Col xs={12} sm={12} key={idx} className={"block-result p-3 mb-3"}>
                                {
                                    el.content.length > 0
                                        ? el.content.map((response, id) => {
                                            return <div className={"mb-2 mb-md-2"} key={id}>{response}</div>
                                        })
                                        : el.content
                                }
                            </Col>
                        })
                }
        </Row>
    )
}

export default ResultComponent;
