import {trans} from "../translations/translations";

export const PAGES_LOGIN = {
    path: "/" + trans('url.login'),
    title: trans('pages.connection.title'),
}
export const PAGES_AUTHENTICATION = {
    path: "/" + trans('url.authentication') + "/:id",
    title: "",
}
export const PAGES_REGISTER = {
    path: "/" + trans('url.register'),
    title: trans('pages.registration.title'),
}
export const PAGES_RECOVERY = {
    path: "/" + trans('url.recovery'),
    title: trans('pages.recovery.title'),
}

export const PAGE_HOME = {
    path: "/" + trans('url.home'),
    title: trans('pages.home.title')
}
export const PAGE_SUCCESS = {
    path: "/" + trans('url.success'),
    title: trans('pages.success.title')
}
export const PAGE_ACTIVITIES = {
    path: "/" + trans('url.activities'),
    title: trans('pages.activities.menu')
}
export const PAGE_PROGRAM = {
    path: "/" + trans('url.program'),
    title: trans('pages.program.title')
}
export const PAGE_HABIT = {
    path: "/" + trans('url.habit'),
    title: trans('pages.habit.title')
}
export const PAGE_PROFILE = {
    path: "/" + trans('url.profile'),
    title: trans('pages.profile.title')
}
export const PAGE_ADVICES = {
    path: "/" + trans('url.advices'),
    title: trans('pages.advice.title')
}
export const PAGE_PERSONALISATION = {
    path: "/" + trans('url.personalisation'),
    title: trans('pages.personalisation.title')
}

export const PAGE_PERSONALISATION_CJA = {
    path: "/" + trans('url.personalisationCja'),
    title: trans('pages.personalisation.title')
}

export const PAGE_RESIGNATION = {
    path: "/" + trans('url.resignation'),
    title: trans('pages.resignation.title')
}
