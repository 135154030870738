import AxiosInstance from "../_services/axiosServices";
import { EndPointCustomerHub, ORIGIN } from '../_constants';

export const TYPE_ERROR = 'ERROR';
export const TYPE_DEBUG = 'DEBUG';

export const SendLog = (type = "", component = "", msg = "", code = "") => {
    const data = {
        origin: ORIGIN,
        component: component,
        type: type,
        message: msg,
        code: code
    }
    AxiosInstance.post(EndPointCustomerHub.log, data)
        .then()
}

export const SendGraylog = (action = null, display = null, msg = null) => {
    const infos = JSON.parse(sessionStorage.getItem('infos'));
    const data = {
        origin: ORIGIN,
        action: action ?? "",
        display: display ?? "",
        user: infos.externalId ?? "",
        message: msg ?? ""
    }
    AxiosInstance.post(EndPointCustomerHub.grayLog, data)
        .then()
}
